.auth-header {
    .logo {
        height: auto;
    
        img { width: 100%; }
    }
}

form {
    h3 { text-align: center; }
}
