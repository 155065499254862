$mock-color: #EDEDED;

.preview-wrapper {
    max-width: 480px;
}

.preview-container {
    position: relative;
    width: 100%;
    padding-top: 200%;
    border-radius: 30px;
    background-color: #fff;
    border: 2px solid $mock-color;
}

.preview-content {
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 15px;
    right: 15px;
    border-radius: 20px;
    border: 1px solid $mock-color;
}
