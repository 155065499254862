@import '~antd/dist/antd.css';
@import '~katex/dist/katex.min.css';

.App {
    width: 100%;
    height: 100%;
}

header .logo {
    height: 35px;
    width: 195px;
    background: url('./assets/logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

header .logo a {
    width: 100%;
    height: 100%;
}
